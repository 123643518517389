<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'
import { getAction } from '@/command/netTool'
import CardIconLabelLocal from '../../../components/CardIconLabelLocal'
import moment from 'moment'
import { hotelStarRating, brandOwnership } from '@/utils/textFile'

export default {
  name: 'hotelHomestayManageDetail',
  data() {
    return {
      // 区域类型 省市区
      areaCode: [],
      ...api.command.getState(),
      detail: {
        starLevelName: '',
        brandOfOwnershipName: ''
      },
      storeData: [],
      labelGroup: [],
      star_hotel_arr: [],
      brand_arr: []
    }
  },

  mounted() {
    // 获取级联数据
    api.command.getRegionType.call(this, {
      url: '/api/region/threeTree',
      key: 'areaCode'
    })

    // getAction('/admin/dict/codes?codes=star_hotel,brand', {}, '/bpi').then(res => {
    //   let data = res.data
    //   let { star_hotel, brand } = data
    //   this.star_hotel_arr = star_hotel.map(e => {
    //     return {
    //       name: e.name,
    //       value: e.id
    //     }
    //   })
    //   this.brand_arr = brand.map(e => {
    //     return {
    //       name: e.name,
    //       value: e.id
    //     }
    //   })
    // })
    const { id } = this.$route.query
    id &&
      api.command.getDetail
        .call(this, {
          url: `/hotel/detail?id=${id}`
        })
        .then(res => {
          this.detail.areaCode = [res.provinceCode, res.cityCode, res.areaCode]
        })
    api.command.getLabelCodeGroup.call(this, {
      url: '/api/base/farmLabelData/getByCodeSelf',
      params: {
        id: id ?? '',
        codes: [
          {
            key: 'farmhouse_public'
          }
        ]
      }
    })

    this.storeSearch()
    this.$forceUpdate()
  },
  methods: {
    async storeSearch(value = '') {
      let searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmMainBody/list?mainBodyName=${value}`
      })
      this.storeData = searchResult.map(el => {
        return {
          name: el.mainBodyName,
          value: el.id
        }
      })
    },
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '名称',
            type: 'input',
            cols: 12,
            key: 'name',
            rules: [
              {
                required: true
              }
            ]
          },
          {
            name: '星级',
            type: 'select',
            cols: 12,
            key: 'starLevel',
            typeData: hotelStarRating(),
            onChange: val => {
              this.detail.starLevelName = hotelStarRating().find(f => f.value === val)?.name || ''
            }
          },
          {
            name: '简写（对于度假村可以将简称加上，很多用户知道简称）',
            type: 'input',
            cols: 12,
            key: 'nameAd'
          },
          {
            name: '所属品牌',
            type: 'select',
            cols: 12,
            key: 'brandOfOwnership',
            typeData: brandOwnership(),
            onChange: val => {
              this.detail.brandOfOwnershipName = brandOwnership().find(f => f.value === val)?.name || ''
            }
          },
          {
            type: 'cascader',
            cols: 12,
            key: 'areaCode',
            name: '所在城市',
            placeholder: '请选择地址',
            typeData: this.areaCode,
            rules: [
              {
                required: true,
                type: 'array',
                message: '请选择地址'
              }
            ]
          },
          //   {
          //     name: '所在城市（直接输入城市名称或输入拼音下拉选择）',
          //     type: 'dropDownInput',
          //     cols: 12,
          //     key: 'mainBodyName',
          //     onInputSearch: this.storeSearch,
          //     valueKey: 'mainBodyId',
          //     inputProps: {
          //       placeholder: '搜索店铺',
          //       addonBefore: <a-icon type="search" />
          //     },
          //     rules: [
          //       {
          //         required: true
          //       }
          //     ]
          //   },
          {
            name: '详细地址',
            type: 'input',
            cols: 12,
            key: 'address',
            rules: [
              {
                required: true
              }
            ]
          },
          {
            name: '酒店电话',
            type: 'input',
            cols: 12,
            key: 'hotelPhone',
            rules: [
              {
                required: true
              }
            ]
          },
          {
            name: '入住时间（后）',
            type: 'timePicker',
            cols: 6,
            key: 'checkInTime',
            props: {
              format: 'HH:mm',
              valueFormat: 'HH:mm',
              allowClear: false
            }
          },
          {
            name: '离店时间（前）',
            type: 'timePicker',
            cols: 6,
            key: 'checkOutTime',
            props: {
              format: 'HH:mm',
              valueFormat: 'HH:mm',
              allowClear: false
            }
          }
        ]
      }
    },
    getForm2() {
      return {
        type: 'cardForm',
        title: () => (
          <div>
            <span style={{ color: '#f5222d' }}>*</span>Banner（尺寸750*420PX）限制1张
          </div>
        ),
        data: [
          {
            type: 'iconUpload',
            cols: 12,
            typeData: [
              {
                maxLength: 1,
                key: 'bannerUrl',
                desc: '<br /> 支持格式.png/.jpg'
              }
            ],
            rules: [
              {
                required: true,
                message: '请上传图片',
                type: 'string'
              }
            ]
          }
        ]
      }
    },
    getLabelForm(index) {
      return [
        {
          name: '标签名称',
          type: 'input',
          key: 'labelName'
        },
        {
          name: '标签类型',
          type: 'radioButton',
          key: 'publicLabel',
          typeData: [
            {
              name: '店铺私有',
              value: '2'
            },
            {
              name: '公用标签库',
              value: '1'
            }
          ]
        },
        {
          name: '标签分类',
          type: 'iconClass',
          key: 'iconUrl'
        }
      ].filter(e => e)
    },
    getForm3() {
      return {
        type: 'row',
        children: (this.labelGroup || []).map((e, i) => {
          return (
            <CardIconLabelLocal
              data={this.getLabelForm(i)}
              title={e.labelTypeName}
              pubBool={false}
              cols={24}
              style={{
                marginBottom: '10px',
                minHeight: '200px'
              }}
              icons={e.list}
            />
          )
        })
      }
    },
    getForm4() {
      return {
        type: 'cardForm',
        cols: 24,
        showCard: false,
        data: [
          {
            type: 'rich',
            key: 'richText',
            cols: 24
          }
        ]
      }
    },
    getSumbitData() {
      const codeText = ['province', 'city', 'area']
      let arr = document.getElementsByClassName('ant-cascader-picker-label')[0].innerHTML.split('/')
      const obj = {}
      arr.forEach((e, i) => {
        obj[codeText[i] + 'Name'] = e
        obj[codeText[i] + 'Code'] = this.detail.areaCode[i]
      })
      return obj
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: data => {
            let codeData = this.getSumbitData()
            // 获取标签
            let arr = []
            this.labelGroup.forEach(item =>
              item.list.forEach(e => {
                e['labelTypeCode'] = item.labelTypeCode
                e['labelTypeId'] = item.id
                e['labelTypeName'] = item.labelTypeName
              })
            )
            this.labelGroup.map(e => arr.push(...e.list))
            let brr = arr.map(e => ({
              ...e,
              czState: e.czState ? 1 : 0
            }))

            let upDown = this.detail.id ? this.detail.upDown : '0'

            let params = {
              ...data,
              ...codeData,
              farmLabelDataDTOS: brr,
              upDown
            }

            api.command[this.detail.id ? 'edit' : 'create']
              .call(this, {
                url: `/hotel/${this.detail.id ? 'update' : 'save'}`,
                params,
                isPost: false
              })
              .then(result => {
                this.$router.push('/commodityManagement/hotelHomestayManage')
              })
          }
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => {
            this.$router.push('/commodityManagement/hotelHomestayManage')
          }
        }
      ]
      const right = [
        {
          name: ` ${this.detail.upDown == 0 ? '下架' : '上架'} `,
          type: 'default',
          onClick: () => {
            getAction(`/api/hotel/${this.detail.upDown == 0 ? 'downBatch' : 'upBatch'}`, {
              idList: [this.detail.id]
            }).then(e => {
              if (e.code == 200) {
                this.$message.success(`${this.detail.upDown == 0 ? '下架成功' : '上架成功'}`)
                setTimeout(() => {
                  this.$router.push('/commodityManagement/hotelHomestayManage')
                }, 500)
              } else {
                this.$message.error(e.msg)
              }
            })
          }
        },
        {
          name: '删除',
          popTitle: '确认删除吗?',
          isPop: true,
          type: 'danger',
          ghost: true,
          onClick: () => {
            api.command.del.call(this, {
              url: `/hotel/deleteBatch`,
              params: {
                idList: [this.detail.id]
              },
              isPost: false
            })
            setTimeout(() => {
              this.$router.push('/commodityManagement/hotelHomestayManage')
            }, 500)
          }
        }
      ]
      return {
        left,
        right
      }
    }
  },
  render() {
    return (
      <DetailFormGroup
        foot={this.getFoot()}
        form={this.detail}
        typeData={{
          mainBodyName: this.storeData
        }}
        data={[this.getForm1(), this.getForm2(), this.getForm3(), this.getForm4()]}
      />
    )
  }
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

/deep/.ant-input[type='number'] {
  -moz-appearance: textfield;
}
/deep/.ant-input[type='number']::-webkit-inner-spin-button,
/deep/.ant-input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
